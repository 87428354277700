<template lang="html">
	<lottie-animation path="data.json" :loop="false" :speed="1.4" :autoPlay="true" />
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
export default {
	name: 'AnimatedLogo',
	components: {
		LottieAnimation
	},
}
</script>

<style lang="css" scoped>

</style>
