<template lang="html">
	<v-main id="main-background">
		<NavBar />
		<v-container>
			<v-layout row justify-center>
				<v-flex xs12 sm12 md12 lg12 xl10>
					<transition name="fade" mode="out-in">
						<router-view :key="$route.name + ($route.params.topicName || '')" />
					</transition>
				</v-flex>
			</v-layout>
		</v-container>
		<Footer />
		<transition name="text-slide">
			<div class="loading-screen white accent--text" v-if="loading">
				<div class="logo-container">
					<AnimatedLogo />
				</div>
			</div>
		</transition>
	</v-main>
</template>

<script>
import AnimatedLogo from './AnimatedLogo'
import NavBar from './NavBar'
import Footer from './Footer'
export default {
	name: 'OuterPage',
	components: {
		AnimatedLogo,
		NavBar,
		Footer
	},
	data() {
		return {
			loading: true
		}
	},
	mounted() {
		setTimeout(() => {
			this.loading = false
		}, 3700)
		if(this.$route.name === 'LandingPage') {
			setTimeout(() => {
				this.$store.dispatch('loadingFinished')
			}, 11000)
		} else {
			setTimeout(() => {
				this.$store.dispatch('loadingFinished')
			}, 4700)
		}
	}
}
</script>

<style lang="css">
#main-background{
	background:#f5f5f5;
	transition:0.5s ease-in-out;
}
.scrolled{
	background: #353839!important;
}
.loading-screen{
	position:fixed;
	top:0;
	left:0;
	height:100%;
	width:100%;
	z-index:1001;
	/* display:flex;
	justify-content: center;
	align-items:center;
	font-size:100px */
}
.logo-container{
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
	width:100%
}
.text-slide-enter-active, .text-slide-leave-active {
	transition: 0.3s ease-out;
}
.text-slide-enter, .text-slide-leave-to {
	letter-spacing:100px;
	opacity:0;
}
.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
	opacity: 0
}
</style>
